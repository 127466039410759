<template>
  <b-card-code class="h-100">
    <b-row class="py-1 justify-content-between">
      <b-col md="4">
        <b-form-input
          v-model="search"
          placeholder="Search by name or amount"
          @input="handleSearch"
        />
      </b-col>
      <div class="d-flex gap-1">
        <!-- <b-button size="sm" variant="primary" @click="handleOpen"
          >Release Payment</b-button
        > -->
        <b-button size="sm" variant="primary" @click="handleMultiCommission('Release')"
          >Release Payment</b-button
        >
        <b-button size="sm" variant="danger" @click="handleMultiCommission('Block')"
          >Block Payment</b-button
        >
      </div>
    </b-row>
    <div style="width: 100%; height: 100%; overflow: auto">
      <table class="table table-bordered" style="min-width: 28.9cm">
        <thead class="text">
          <tr>
            <th>
              <div class="d-flex gap-1">
                <b-form-checkbox v-model="allChecked" @input="handleAll($event)">
                </b-form-checkbox>
                Sr. No
              </div>
            </th>
            <th>Type</th>
            <th>User Name</th>
            <th>Earning</th>
            <th>TDS</th>
            <th>Admin Charges</th>
            <th>Net Amount</th>
            <th>%</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, id) in commissionList" :key="id">
            <td>
              <div class="d-flex gap-1">
                <b-form-checkbox v-model="item.checked"> </b-form-checkbox>
                {{ id + 1 }}
              </div>
            </td>
            <td>{{ item.user ? setRole(item.user.role) : "" }}</td>
            <td>{{ item.user ? item.user.fullname : "" }}</td>
            <td>{{ item.earning }}</td>
            <td>{{ item.tds_amount }}</td>
            <td>{{ item.admin_charges }}</td>
            <td>{{ item.net_amount }}</td>
            <td>{{ item.per }}</td>
            <td>{{ item.status }}</td>
            <td>
              <span class="ml-2">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none p-0"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      width="30"
                      height="30"
                      icon="AlignRightIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click="handleCommission([item.id], 'Release')"
                    variant="primary"
                  >
                    <b> Release Payment</b>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="handleCommission([item.id], 'Block')"
                    variant="primary"
                  >
                    <b>Block Payment</b>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </td>
          </tr>
          <tr v-if="commissionList.length == 0">
            <td colspan="9">
              <div class="d-flex justify-content-center w-100">No data found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal
      id="modal-form"
      v-model="open"
      cancel-variant="outline-secondary"
      ok-title="Submit"
      cancel-title="Close"
      size="md"
      hide-footer
      no-close-on-backdrop
      @hidden="closeModal"
    >
      <b-row>
        <b-col md="12">
          <validation-observer ref="simpleRules">
            <b-form-group>
              <label>Bank Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Ledger" rules="required">
                <v-select
                  v-model="bank"
                  placeholder="None"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="bankOption"
                >
                  <template #option="{ name, accountno }">
                    <span> {{ name }} {{ accountno ? " / " + accountno : "" }} </span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </validation-observer>
        </b-col>
        <b-col md="12" class='d-flex justify-content-end' >
        <div class="d-flex flex-wrap" style="gap:10px">
          <b-button variant="secondary" @click="closeModal">Cancel</b-button>
          <b-button variant="primary" @click="handleSubmit">Save</b-button>
        </div>
        </b-col>
      </b-row>
    </b-modal>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import axios from "@/components/axios";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import {
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormInput,
  BRow,
  BCol,
  BModal,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";

import _ from "lodash";

export default {
  components: {
    BCardCode,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      commissionList: [],
      allChecked: false,
      search: "",
      open: false,
      bank: "",
      bankOption: [],
    };
  },
  computed: {
    setRole() {
      return (item) => {
        let f = item.charAt(0).toUpperCase();
        return f + item.slice(1);
      };
    },
  },
  mounted() {
    this.getCommissionList();
    this.getBank();
  },
  methods: {
    handleOpen() {
      let selected = this.commissionList.filter((item) => item.checked);
      selected = selected.map((item) => item.id);
      if (selected.length == 0) {
        this.$swal({
          icon: "warning",
          title: "Warning",
          text: "Please select atleast one!",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        this.bank=''
        this.open = true;
      }
    },
    handleSubmit() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (!success) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the Required Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        } else {
          let selected = this.commissionList.filter((item) => item.checked);
          selected = selected.map((item) => item.id);
          this.handleCommission(selected, "Release");
        }
      });
    },
    async getBank() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getbank`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.bankOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    closeModal() {
      this.open = false;
    },
    handleAll(e) {
      this.commissionList.map((item) => {
        item.checked = e;
      });
    },
    async getCommissionList() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getCommissionList/Unpaid`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: { search: this.search },
      })
        .then((json) => {
          this.commissionList = json.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    handleSearch: _.debounce(function () {
      this.getCommissionList();
    }, 1000),
    async handleMultiCommission(status) {
      let selected = this.commissionList.filter((item) => item.checked);
      selected = selected.map((item) => item.id);

      if (selected.length == 0) {
        this.$swal({
          icon: "warning",
          title: "Warning",
          text: "Please select atleast one!",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        this.handleCommission(selected, status);
      }
    },
    async handleCommission(ids, status) {
      let data = {
        ids: ids,
        status: status,
        bank: this.bank?.id,
      };
      await axios({
        method: "POST",
        url: `${this.baseApi}/chageCommissionStatus`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.$swal({
            icon: "success",
            title: "Success!",
            text: "Successfully Saved",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.getCommissionList();
          this.open=false
        })
        .catch((error) => {
          console.log(error, "error");

          this.$swal({
            icon: "error",
            title: "Error!",
            text: "Something went wrong",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        });
    },
  },
};
</script>
<style>
.gap-1 {
  gap: 10px;
}
</style>
</style>
